<div class="container-fluid" style="border-radius: 2%; box-shadow: 0px 0px 2px 1px rgba(0,0,0,0.2);
    background:white">
  <header class="upload-header">
    <span style="position:relative; display: block">
      <i class="fa fa-close close-icon" (click)="onNoClick()"></i>
    </span>
    <p>
      <i class="fa fa-cloud-upload" aria-hidden="true"></i>
      <span class="font-weight-bold">up</span>
      <span class="font-weight-light">load</span>
    </p>
  </header>
  <h1 class="text-center" mat-dialog-title>{{data.title}}</h1>

  <div class="row" mat-dialog-content>
    <h5>Accepted file formats:
      <p style="font-size: 14px">{{data.fileformat}}</p>
    </h5>
  </div>

  <div class="row">
    <span class="col-md-1"></span>
    <label class="col-md-3 btn btn-info">
    Browse
    <input type="file" id="chooseFile" hidden required name="chooseFile"
          #chooseFile (change)="uploadedFileCheck(chooseFile.files)"/>
    </label>
    <input type="text" style="background: transparent"
      [value]="chooseFile.files.length > 0 ? chooseFile.files[0].name : ''"
      disabled class="form-control col-md-6 disabled  text-muted" id="file-text"/>
  </div>

  <p class="mt-3 small text-muted">Note: {{data.note}}</p>
  <h6 class="text-dark" *ngIf="errorMessage">{{errorMessage}}</h6>
  <div class="row text-center border-top border-bottom" mat-dialog-actions *ngIf="fileValid">
    <button mat-button (click)="submitFile(chooseFile.files)" class="col-md-12 btn btn-primary">
        <i class="fa fa-cloud-upload">
          <span style="font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif">{{data.buttonName}}</span>
        </i>
    </button>
  </div>
  <div class="row" *ngIf="!fileValid">
    <hr/>
  </div>
  <br/>
  <p *ngIf="value">Response: {{value}}</p>
  <br/>

</div>
