import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { UploadDialogComponent } from 'src/app/ui-component/custom-ui-components/modals/upload-dialog/upload-dialog.component';
import { UploadModalService } from 'src/app/ui-component/custom-ui-components/modals/upload-dialog/upload-modal.service';
import { MatModuleModule } from 'src/app/ui-component/mat-module/mat-module.module';

//import { MatSortModule, MatSort, MatTableDataSource, MatTableModule, MatIconModule } from '@angular/material';
import { ConfirmDialogComponent } from '../custom-ui-components/modals/confirm-dialog/confirm-dialog.component';
import { SpinnerComponent, SpinnerDialog } from './spinner/spinner.component';
import { AppToastComponent } from './toaster/app-toast.component';
import { SpinnerDialogComponent } from 'src/app/ui-component/custom-ui-components/modals/spinner-new/spinner-dialog.component';
import { SessionTimeoutDialogComponent } from 'src/app/ui-component/custom-ui-components/modals/session-timeout-dialog/session-timeout-dialog.component';
//import { AlertComponent } from './modals/alert/alert.component';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { DownloadReportPopupComponent } from './modals/download-report-popup/download-report-popup.component';
import { FormsModule } from '@angular/forms';
import { MessageDialogComponent } from './modals/message-dialog/message-dialog.component';



@NgModule({
	imports: [CommonModule, MatModuleModule, MatIconModule,
		MatDialogModule,
		MatButtonModule, FormsModule],
	declarations: [
		SpinnerDialog,
		SpinnerComponent,
		AppToastComponent,
		UploadDialogComponent,
		ConfirmDialogComponent,
		DownloadReportPopupComponent,
		MessageDialogComponent,
		SpinnerDialogComponent,
		SessionTimeoutDialogComponent,
		DownloadReportPopupComponent,
		//	AlertComponent
	],
	exports: [

		// AlertService,


		SpinnerDialog,
		SpinnerComponent,
		AppToastComponent,
		UploadDialogComponent,
		ConfirmDialogComponent,
		DownloadReportPopupComponent,
		SpinnerDialogComponent,
		SessionTimeoutDialogComponent
	],
	entryComponents: [
		SpinnerDialog,
		UploadDialogComponent,
		ConfirmDialogComponent,
		DownloadReportPopupComponent,
		SpinnerDialogComponent,
		SessionTimeoutDialogComponent,
		MessageDialogComponent
	]
})
export class CustomUiComponentsModule { }
