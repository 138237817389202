//import { AttemptsLog } from './AttemptsLog';

export class requestDetails {
        public requestId: number;
        public expertise: string;
        public learnerId: string;
        public learnerName: string;
        public learnerEmail:string;
        public courseId:number;

        public learnerDesignation: string;
        //public yorbitRequestId : string;
        //public location: string;
        //public email: string;
        //public yorbitCourseId: string;
        public courseName: string;
        public academy: string;
        //public assignmentAttemptId: string;
        public score:number;
        public comments: string;
        public resultId: string;
        public resultName: string;
        public assignmentSolutionFileName: string;
        public scoreCardFileName: string;
        public assignmentSolutionUrl: string;
        public scoreCardUrl: string;
        public problemStatementUrl:string;
        public problemStatementFileName:string;
        public evaluationSheetFileName:string;
        public evaluationSheetUrl:string;
        //public attemptsLog : AttemptsLog[];
        public errorFileUrl: string;
        public errorFileName : string;
        public evaluatorId : string;
        public imageUrl?:string;
        public passingScore: number;
        public maxScore: number;
        public skill: string;
        public scoreCard: string;
        public evalutaionTemplate: string;
        public solutionFile: string;
        public problemStatement: string;
    }