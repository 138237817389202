<section>
  <div class="">
    <div class="row rowfix">
      <div class="col-md-12 ">
         <!-- <table class="table  blueTable">
              <thead>
                <tr class='dotted'>
                  <th>Submission Date</th>
                  <th>Evaluation Date</th>
                  <th>Status</th>
                </tr>

                <tr >
                  <th>03/01/2019</th>
                  <th>10/01/2019</th>
                  <th>Cleared the Project and Yet to attempt assessment</th>
                </tr>
              </thead>
              <tbody>
                 <tr *ngFor="let attempts of requestDetailsModel?.attemptsLog; let i = index">
                  <td>{{attempts?.submissionDate | date:'MMM d, y h:mm a'}}</td>
                  <td class="alignMessage" *ngIf="attempts?.evaluationDate == '0001-01-01T00:00:00'">-</td>
                  <td *ngIf="attempts?.evaluationDate != '0001-01-01T00:00:00'">{{attempts?.evaluationDate | date:'MMM d, y h:mm a'}}</td>
                  <td>{{attempts?.resultName}}</td>
                </tr>
                <tr *ngIf="requestDetailsModel?.attemptsLog.length == 0">
                  <td colspan="3" class="alignMessage">No Attempts Registered</td>
                </tr> 
              </tbody>
            </table>-->
            <table class="data table">
              <tr>
                <th class="dateTd">Submission Date</th>
                <th class="dateTd">Evaluation Date</th>
                <th class="resultTd">Result</th>
                <th>Status</th>
              </tr>
              <tr *ngFor="let log of logDetails">
                <td class="dateTd">{{log.submissionDate | date:'dd/MM/yyyy HH:mm' }}</td>
                <td class="dateTd">{{(log.status == 'Result awaited for project') ? 'NA' :(log.evaluationDate) ? (log.evaluationDate | date:'dd/MM/yyyy HH:mm') : 'NA'  }}</td>
                <td class="resultTd">{{(log.status == 'Result awaited for project') ? 'NA' : (log.result) ? log.result : 'NA'}}</td>
                <td>{{log.status}}</td>
              </tr>
            </table>
      </div>
    </div>
  </div>
  <!--<form [formGroup]="ResultDetailsForm">
    <div class="row rowfix" [hidden]="true">
      <div class="form-group col-xs-3 col-sm-3 col-md-3" *ngIf="showScoreCard">
        <label for="scoreCard">Score Card:</label>
        <div class="disclaimer text-left text-muted" style="font-size: 10px" *ngIf="showScoreCard">
            (optional)
        </div>
      </div>
      <div class="form-group col-xs-9 col-sm-9 col-md-9" >
         
         <input type="file" id="scoreCard" name="scoreCard" accept=".zip,.rar,.7zip" #file formControlName="scoreCard" (change)="onFileChange($event)">
        <input type="file" id="scoreCard" name="scoreCard" formControlName="scoreCard" (change)="onFileChange($event)">  
       
        
        <div class="errorDiv">
          <div *ngIf="ResultDetailsForm.invalid && ResultDetailsForm.get('scoreCard').errors">
            <div *ngIf="ResultDetailsForm.get('scoreCard').errors.pattern" class="error">
              Only .zip Files
            </div>
          </div>
        </div>
      </div>
    </div>  </form>-->
</section>