import { Component, OnInit, Inject } from '@angular/core';
import { MatDatepicker, MatDatepickerInputEvent } from '@angular/material/datepicker';
import {  MatDialogConfig, MatDialog, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { RequestService } from 'src/app/global-component/services/api-mapping-services/request.service';
import * as XLSX from 'xlsx';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-download-report-popup',
  templateUrl: './download-report-popup.component.html',
  styleUrls: ['./download-report-popup.component.scss']
})
export class DownloadReportPopupComponent implements OnInit {


  selectedFromDate: Date;
  selectedEndDate: Date;
  isDateInvalid: boolean = true;
  isDownloaded: boolean = false;
  errorText: any;
  mid: any;
  message: any;
  maxDate: Date;
  minDate: Date;
  datePipe = new DatePipe('en-US');

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    private requestService: RequestService,
    public dialogRef: MatDialogRef<DownloadReportPopupComponent>) {
    this.mid = data;
  }

  ngOnInit() {
    this.maxDate = new Date();
    this.minDate = new Date("2020-03-11");
  }

  openCalendar(picker: MatDatepicker<Date>) {
    picker.open();
  }

  fromDateSelected(event: MatDatepickerInputEvent<Date>) {
    this.selectedFromDate = event.value;
    const todayDate = new Date();
    if (this.selectedFromDate > todayDate) {
      this.isDateInvalid = true;
      this.errorText = "*From Date should not be greater than today's date";
    }
    else if (this.selectedEndDate < this.selectedFromDate) {
      this.isDateInvalid = true;
      this.errorText = '*To date should be greater than From Date';
    }
    else if (this.selectedEndDate > todayDate) {
      this.isDateInvalid = true;
      this.errorText = "*To Date should not be greater than today's date";
    }
    else if (this.selectedFromDate <= this.selectedEndDate) {
      this.isDateInvalid = false;
    }

    console.log('From', this.selectedFromDate);
  }

  toDateSelected(event: MatDatepickerInputEvent<Date>) {
    console.log('from', this.selectedFromDate, this.selectedEndDate);
    this.selectedEndDate = event.value;
    const todayDate = new Date();
    if (this.selectedEndDate > todayDate) {
      this.isDateInvalid = true;
      this.errorText = "*To Date should not be greater than today's date";
    }
    else if (this.selectedEndDate < this.selectedFromDate) {
      this.isDateInvalid = true;
      this.errorText = '*To date should be greater than From Date';
    }
    else if (this.selectedFromDate > todayDate) {
      this.isDateInvalid = true;
      this.errorText = "*From Date should not be greater than today's date";
    }
    else {
      this.isDateInvalid = false;
    }
    console.log('To', this.selectedEndDate);
  }

  downloadReport() {

    var fromDate = new Date(this.selectedFromDate).toISOString();
    var toDate = new Date(this.selectedEndDate).toLocaleDateString();
    console.log('fromt', fromDate);
    this.requestService.getDataForDownloadEvaluationReport(this.mid, this.selectedFromDate, this.selectedEndDate)
      .subscribe(response => {
        console.log('data', response.length);
        if (response.length == 0) {
          this.isDateInvalid = true;
          this.errorText = "No records to download for the selected time frame."
        }
        else if (response.length > 0) {
          var sNo = 0;
          var report = response.map(x => {
            sNo += 1;
            return {
              'Sl.No': sNo,
              'Evaluation Request Id': x.evaluationRequestId,
              'Request Id': x.requestId,
              'Learner Name': x.learnerName,
              'Learner Id': x.learnerId,
              'Course Name': x.courseName,
              'Academy': x.academy,
              'Submission Date': this.datePipe.transform(x.submissionDate, 'MM/dd/yyyy'),
              'Evaluation Date': this.datePipe.transform(x.evaluationDate, 'MM/dd/yyyy'),
              'SLA Hit Date': this.datePipe.transform(x.slaHitDate, 'MM/dd/yyyy'),
              'Is SLA Passed': x.isSLAPassed == true ? 'Yes' : 'No',
              'Sub Status': x.subStatus,
              'Evaluation Request Type': x.evaluationRequestType,
              'Solution': x.solution,
              'Attempts': x.attempts,
              'Result': x.result,
              'Acquired Score': x.score,
              'Passing Score': x.passingScore,
              'Max Score': x.maxScore,
              'Evaluator Id': x.evaluatorId,
              'Evaluator Name': x.evaluatorName,
              'Vendor Name': x.vendorName
            }
          });
          console.log('sfa', report);
          const ws = XLSX.utils.json_to_sheet(report);//converts a DOM TABLE element to a worksheet 
          console.log('ws', ws);
          const wb: XLSX.WorkBook = XLSX.utils.book_new();
          XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

          // save to file
          XLSX.writeFile(wb, 'EvaluationReport.xlsx');
          this.isDownloaded = true;
          this.message = "Downloaded Succesfully";
        }
      });
  }

  closePopup() {
    this.dialogRef.close();
  }
}
