import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatMenuModule } from '@angular/material/menu';
import { MatInputModule } from '@angular/material/input';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatSelectModule } from '@angular/material/select';
import { MatTabsModule } from '@angular/material/tabs';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatDialogModule } from '@angular/material/dialog';
@NgModule({
	imports: [
		CommonModule,
		MatTabsModule,
		MatInputModule,
		MatDatepickerModule,
		MatButtonModule,
		MatCheckboxModule,
		MatMenuModule,
		MatSelectModule,
		MatProgressSpinnerModule,
		MatDialogModule,
		MatPaginatorModule,
		MatTooltipModule
	],
	declarations: [],
	exports: [
		MatTabsModule,
		MatInputModule,
		MatDatepickerModule,
		MatButtonModule,
		MatCheckboxModule,
		MatMenuModule,
		MatSelectModule,
		MatProgressSpinnerModule,
		MatDialogModule,
		MatPaginatorModule,
		MatTooltipModule
	],
	providers: []
})
export class MatModuleModule {}
