import { Component, OnInit, Inject, Sanitizer, Input } from '@angular/core';
import { requestDetails } from 'src/app/models/requestDetails';
import { RESULT_CODES } from 'src/app/models/Constants/RESULT_CODES';
import { Evaluation } from 'src/app/models/Evaluation';
import { RequestService } from 'src/app/global-component/services/api-mapping-services/request.service';
import { DownloadHelperService } from 'src/app/global-component/services/helpers/DownloadHelperService/downloadhelper.service';
import { responseStatus } from 'src/app/models/responseStatus';
import { AppToastService } from 'src/app/ui-component/custom-ui-components/toaster/app-toast.service';
import { AppService } from 'src/app/app.service';
import { ROLES_CONST } from 'src/app/models/Constants/ROLES_CONST';
import { ConfirmDialogService } from 'src/app/ui-component/custom-ui-components/modals/confirm-dialog/confirm-dialog.service';
import { StorageHelperService } from 'src/app/global-component/services/wrappers/storage-helper.service';
import { Router, ActivatedRoute } from '@angular/router';
import { VerifyRoleService } from 'src/app/verify-role.service';
import { DomSanitizer } from '@angular/platform-browser';
import { WINDOW } from 'src/app/global-component/shared/services/window.service';
import { LogDetails } from 'src/app/models/LogDetails';

@Component({
	selector: 'app-requestdetails',
	styleUrls: ['./requestdetails.component.css'],
	templateUrl: './requestdetails.component.html'
})
export class RequestdetailsComponent implements OnInit {
	public evaluationRequestId: number;
	public evaluationRequestType: string;
	public requestDetails: requestDetails;
	public resultCodes = RESULT_CODES;
	graphUserImage: Blob;
	public loading: boolean;
	public evaluation: Evaluation;
	public model: responseStatus;
	public message: string;
	public response: responseStatus;
	public isLearner: boolean;
	public resultStatus: String = RESULT_CODES.notclear;
	done: boolean;
	error: boolean;
	wait: boolean = true;
	imageLoaded: boolean;
	imageUrl: any;
	public logInput: any;
	@Input() public requestDetailsModel: requestDetails;
	constructor(
		private confirmDialogService: ConfirmDialogService,
		@Inject(WINDOW) private _window: Window,
		private sanitizer: DomSanitizer,
		private downloadHelperService: DownloadHelperService,
		private requestService: RequestService,
		private toasterService: AppToastService,
		private appService: AppService,
		private roleService: VerifyRoleService,
		private storageHelperService: StorageHelperService,
		private router: Router,
		private route: ActivatedRoute
	) {
		this.evaluation = new Evaluation();
		this.evaluationRequestType = route.snapshot.params['evaluationRequestType'];
		this.evaluationRequestId = route.snapshot.params['requestId'];
	}

	public ngOnInit() {
		console.log('details hit', this.evaluationRequestType, this.evaluationRequestId);
		this.loadRequestDetails(this.evaluationRequestType, this.evaluationRequestId);
		this.logInput = {
			'EvaluationRequestId': this.evaluationRequestId,
			'EvaluationRequestType': this.evaluationRequestType
		};


		//	this.getUserImage('M1011212');
	}

	getUserImage(learnerMid) {
		console.log("entered into getUserImage method")
		this.imageLoaded = false;
		this.requestService.getUserImage(learnerMid).subscribe(

			data => {

				this.graphUserImage = data;
				this.imageUrl = '';
				this.imageLoaded = true;
			}
		);

		console.log("service getUserImage method")
	}

	// createImageURL(imageBlob) {
	// 	const imageUrl = this._window.URL.createObjectURL(imageBlob);
	// 	return this.sanitizer.bypassSecurityTrustUrl(imageUrl);
	// 	console.log("Image URL");

	// }

	public loadRequestDetails(evaluationRequestType: string, requestId: number) {
		this.loading = true;
		this.requestService.getRequestDetails(evaluationRequestType, requestId).subscribe(data => {
			if (data) {
				this.requestDetails = data as requestDetails;
				console.log('requestDetails', this.requestDetails);

				this.loading = false;
				this.isLearner =
					this.appService.userMid() === this.requestDetails.learnerId;
			}
			//	this.getUserImage(this.requestDetails.learnerId);
		}
		);

	}

	public resultDetails(value: FormData) {
		this.updateEvaluation(this.evaluationRequestId, value);
	}

	public updateEvaluation(requestId: number, evaluation: FormData) {
		this.confirmDialogService
			.confirmDialog({
				message: 'Are you sure, you want to submit provided data?',
				confirmButtonName: 'Confirm',
				cancelButtonName: 'Cancel',
				title: `Evaluate Request`
			})
			.subscribe(x => {
				if (x) {
					this.requestService.updateEvaluationResult(requestId, evaluation).subscribe(data => {
						this.response = data as responseStatus;
						if (this.response.isSuccess === true) {
							this.message = 'Successfully Updated';
							this.toasterService.showInfo(this.message);
							this.loadRequestDetails(this.evaluationRequestType, requestId);
						} else if (this.response.isSuccess === false) {
							this.toasterService.showInfo(this.response.message);
						}
					});
				}
			});
	}

	public downloadScoreCard(FileUrl: string) {
		this.downloadHelperService.getUrlWithSASUri(FileUrl).then(data => {
			if (data != '' && data.length > 0) {
				let decodedUri = decodeURIComponent(escape(window.atob(data)));
				window.open(decodedUri, '_blank');
			}
			else {
				// alert('No File def');
				this.message = 'No file available to download';
				this.toasterService.showInfo(this.message);
			}
		});

	}

	public downloadAssignment(FileUrl: string) {
		console.log(FileUrl, "ProblemstatementBLOBPath");
		this.downloadHelperService.getUrlWithSASUri(FileUrl).then(data => {
			if (data != '' && data.length > 0) {
				console.log('data', data);
				let decodedUri = decodeURIComponent(escape(window.atob(data)));
				window.open(decodedUri, '_blank');
				const requestIds: number[] = [];
				requestIds.push(this.evaluationRequestId);
			} else {
				this.message = 'No file available to download';
				this.toasterService.showInfo(this.message);
			}
		});
	}

	public downloadAssignmentDisable(FileUrl: string) {
		if (FileUrl != null && FileUrl.length > 0) {
			return false;
		} else {
			return true;
		}
	}

	public downloadScoreCardDisable(FileUrl: string) {
		if (FileUrl != null && FileUrl.length > 0) {
			return false;
		} else {
			return true;
		}
	}


	public downloadEvaluationSummarySheet(FileUrl: string) {
		console.log('Evaluation Url', FileUrl);
		this.downloadHelperService.getUrlWithSASUri(FileUrl).then(data => {
			if (data != '' && data.length > 0) {
				let decodedUri = decodeURIComponent(escape(window.atob(data)));
				window.open(decodedUri, '_blank');
			} else {
				this.message = 'No file available to download';
				this.toasterService.showInfo(this.message);
			}
		});

	}

	public downloadEvaluationSummarySheetDisable(FileUrl: string) {
		if (FileUrl != null && FileUrl.length > 0) {
			return false;
		} else {
			return true;
		}
	}


	public downloadProblemStatement(FileUrl: string) {
		console.log(FileUrl, "ProblemstatementBLOBPath");
		this.downloadHelperService.getUrlWithSASUri(FileUrl).then(data => {
			if (data != '' && data.length > 0) {
				let decodedUri = decodeURIComponent(escape(window.atob(data)));
				window.open(decodedUri, '_blank');

			} else {
				this.message = 'No file available to download';
				this.toasterService.showInfo(this.message);
			}
		});

	}

	public downloadProblemStatementDisable(FileUrl: string) {
		if (FileUrl != null && FileUrl.length > 0) {
			return false;
		} else {
			return true;
		}
	}

	/* public postErrorAsync(form: FormData) {
		this.confirmDialogService
			.confirmDialog({
				message: 'Are you sure you want to upload error data for this request?',
				confirmButtonName: 'Confirm',
				cancelButtonName: 'Cancel',
				title: `Evaluate Request`
			})
			.subscribe(x => {
				if (x) {
					this.message = '';
					form.append('mid', this.requestDetails.learnerId);
					this.requestService.postErrorAsync(this.requestId, form).subscribe(
						data => {
							this.model = data as responseStatus;
							if (this.model.isSuccess === true) {
								this.message = 'Successfully Uploaded';
								this.toasterService.showInfo(this.message);
								this.loadRequestDetails(this.evaluationRequestType,this.requestId);
							}
							if (this.model.isSuccess === false) {
								this.message = this.model.message;
								this.toasterService.showInfo(this.message);
							}
						},
						error => {
							//this.message = 'Error in file Upload';
							this.message = 'Error file uploaded';
							this.toasterService.showError(this.message);
						}
					);
				}
			});
	} */

	public backToList() {
		if (this.requestDetails.learnerId === this.appService.userMid()) {
			this.router.navigate(['request/pending-requests']);
		} else {
			this.router.navigate(['request/pending-requests']);
		}
	}
	public getUpdateStatus(status) {
		this.resultStatus = status;
		this.error = false;
		this.done = false;
		this.wait = false;
		if (this.resultStatus == RESULT_CODES.notclear) {
			this.wait = true;
		}
		else if (this.resultStatus == RESULT_CODES.cleared) {
			this.done = true;
		}
		else if (this.resultStatus == RESULT_CODES.error) {
			this.error = true;
		}
	}
}
