<div class="font14">
    <div>
        <fieldset [disabled]= "isCompletedRequest">
      <div class="radioButtonDiv">
          <input type="radio" name="resultId" [(ngModel)]="radioButtonValue"
          [value]="resultCodes.cleared" (change)="getStatus(resultCodes.cleared)">Cleared
        <input type="radio" name="resultId" [(ngModel)]="radioButtonValue"
          [value]="resultCodes.notclear" (change)="getStatus(resultCodes.notclear)">Not Cleared
        <input type="radio" name="resultId" [(ngModel)]="radioButtonValue"
          [value]="resultCodes.error" (change)="getStatus(resultCodes.error)">Errors
      </div>
    </fieldset>
    <div class="fieldsDiv">
      <table class="fieldsTable">
        <tr>
          <td class="verticalAlign">Comments :</td>
          <td colspan="3">
            <textarea class="textArea" placeholder="Comments" [disabled]= "isCompletedRequest"
            [(ngModel)]="comments" (keyup)="onTextAreaChange($event)" maxlength="500" ></textarea>
          </td>
          <td></td>
        </tr>
        <tr>
          <td></td>
          <td>
            <div class="errorText alignWithSecondColumn" *ngIf="isCommentsEmpty">
              *Comments are mandatory
            </div> 
          </td>
        </tr>
        <tr class="row3">
          <td class="scoreText">
            <div *ngIf="showScore">Score :</div>
            <div *ngIf="!showScore">Error :</div>
          </td>
          <td *ngIf="showScore">
              <div class="scoreDiv" *ngIf="showScore">
                  <input type="text" (keyup)="onScoreChange($event)" class="form-control" 
                  [(ngModel)]= "scoreSecured" [disabled]= "isCompletedRequest" placeholder="">
                </div>
          </td>
          <td *ngIf="showScore">
            <div class="scoreCardText">Score Card :</div>
          </td>
          <td class="fileTD">
            <div *ngIf="showScore"> <input type="text" class="form-control background" maxlength="8" id="scorecardPath"
              name="scorecardPath" placeholder={{clearScoreCard}} readonly></div>
          <div *ngIf="!showScore" class="alignWithSecondColumn"> <input type="text" class="form-control background" maxlength="8" id="errorPath"
              name="errorPath" 
              placeholder={{notClearedScorecard}} readonly></div>
          </td>
          <td>
            <div class="sprite_up_icon" *ngIf = "!isCompletedRequest" (click)="file.click()"></div>  
            <div class="sprite_up_icon greyedOut" *ngIf = "isCompletedRequest"></div>  
          </td>
        </tr>
        <tr>
          <td></td>
          <td>
            <table class="minAndMaxScore" *ngIf="showScore">
              <tr>
                <td>Passing Score</td>
                <td>:</td>
                <td>{{requestDetailsModel.passingScore}}</td>
              </tr>
              <tr>
                  <td>Max Score</td>
                  <td>:</td>
                  <td>{{requestDetailsModel.maxScore}}</td>
                </tr>
            </table>
         
            <div class="uploadTypes alignWithSecondColumn" *ngIf="!showScore">
              Note: Upload any file type less than 5MB. 

            </div>
          </td>
          <td></td>
          <td> 
            <div class="uploadTypes" *ngIf="showScore">
              Note: Upload any file type less than 5MB. 
          </div>
        </td>
        </tr>
        <tr>
          <td></td>
          <td>
            <div class="errorText alignWithSecondColumn" *ngIf="isScoreError && showScore">
                  {{scoreErrorText}}
            </div>
            <div class="errorText alignWithSecondColumn" *ngIf= "isFileEmpty && !showScore">
                {{fileErrorText}}
            </div>
            <div class="errorText alignWithSecondColumn" *ngIf= "fileExtentions>1 && !showScore" >
              {{fileExtentionsErrorText}}
          </div>
          </td>
          <td></td>
          <td class="errorTd"><div class="errorText" *ngIf= "isFileEmpty && showScore">
              {{fileErrorText}}
          </div>
          <div class="errorText" *ngIf= "fileExtentions>1 && showScore" >
            {{fileExtentionsErrorText}}
        </div>
        </td>
        </tr>
      </table>
    </div>

  <div class="row rowfix" [hidden]="true">
    <div class="form-group col-xs-9 col-sm-9 col-md-9">

      <input type="file" id="scoreCard" name="scoreCard" 
      [disabled]= "isCompletedRequest" #file formControlName="scoreCard"
      (click)="file.value = null" value="" 
      (change)="onFileChange($event)">
      <!-- <input type="file" id="scoreCard" name="scoreCard" formControlName="scoreCard" (change)="onFileChange($event)">  -->
      </div>
      
  </div>

</div>
  <!--[disabled]='!ResultDetailsForm.valid' [hidden]="(requestDetailsModel?.resultId != resultCodes.evaluate.toLowerCase() || appService.userRoleId() === roles.Learner || requestDetailsModel && appService.userMid() === requestDetailsModel.learnerMid)"-->
  <div class="submitButtonDiv" style="text-align:right" align="center">

    <button name="submitButton" [disabled]="submitButtonText!='Submit' || isDisabled == true" class="btn btn-primary btn-prm-override" [ngClass] = "{disableMouse :isDisabled}" (click)="submitDetails()"   
     >{{submitButtonText}}</button>
  </div>
  </div>
