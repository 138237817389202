<section>
    <div style="float:left">
    <input matInput (keyup)="applyFilter($event.target.value)" class="ip2" placeholder="Search by Name or MID or Req ID">
</div>
<div style="text-align: right">
  <mat-icon>filter_list</mat-icon>
    <mat-form-field appearance="outline">
  <mat-select placeholder="Filter By Sub-Status" (selectionChange)="applySubStatusFilter($event)">
    <mat-option *ngFor="let subStatus of subStatusList" [value]="subStatus">
      {{subStatus}}
    </mat-option>
  </mat-select>
  </mat-form-field>
  

</div>
</section>
<br>

<section>
  <div class="row rowfix">
        <table mat-table id="requestTable"  #table [dataSource]="sourceData" matSort class="table col-12" style="min-height: 250px">

          <!-- Check Box Column -->
          <ng-container matColumnDef="select">
              <mat-header-cell class="row rowfix bg-info title-box table-header" *matHeaderCellDef>
                <mat-checkbox (change)="$event ? masterToggle() : null"
                              [checked]="selection.hasValue() && isAllCheckBoxSelected()"
                              [indeterminate]="selection.hasValue() && !isAllCheckBoxSelected()">
                </mat-checkbox>
              </mat-header-cell>
              <mat-cell *matCellDef="let row">
                <mat-checkbox (click)="$event.stopPropagation()"
                              (change)="toggleInSelectedRequest(row.requestId)"
                              [checked]="selection.isSelected(row)">
                </mat-checkbox>
              </mat-cell>
            </ng-container>

            <!-- Cloud Download Column -->
            <ng-container matColumnDef="cloudDownload">
                <mat-header-cell class="row rowfix bg-info title-box table-header" *matHeaderCellDef>
                  
                </mat-header-cell>
                <mat-cell *matCellDef="let element">
                    <mat-icon (click)="downloadAssignment(element.requestId,element.downloadLink)">cloud_download</mat-icon>
                </mat-cell>
              </ng-container>
            
  
                <!-- RequestID Column --> 
                <ng-container matColumnDef="requestId">
                  <mat-header-cell class="row rowfix bg-info title-box table-header" *matHeaderCellDef mat-sort-header>Request ID</mat-header-cell>
                  <mat-cell [ngClass]="{ 'request-primary-2' : i % 2 == 0 }" *matCellDef="let element" (click)="getRecord(element.requestId)"> {{element.requestId}} </mat-cell>
                </ng-container>
              
                <!-- Course Name Column -->
                <ng-container matColumnDef="courseName">
                  <mat-header-cell class="row rowfix bg-info table-header" *matHeaderCellDef mat-sort-header>Course Name</mat-header-cell>
                  <mat-cell *matCellDef="let element"> {{element.courseName}} </mat-cell>
                </ng-container>
              
                <!-- Learner Name Column -->
                <ng-container matColumnDef="learnerName">
                  <mat-header-cell class="row rowfix bg-info table-header" *matHeaderCellDef mat-sort-header>Learner's Name</mat-header-cell>
                  <mat-cell *matCellDef="let element"> {{element.learnerName}} </mat-cell>
                </ng-container>
              
                <!-- MID Column -->
                <ng-container matColumnDef="mid">
                  <mat-header-cell class="row rowfix bg-info table-header" *matHeaderCellDef mat-sort-header>MID</mat-header-cell>>
                  <mat-cell *matCellDef="let element"> {{element.mid}} </mat-cell>
                </ng-container>

                <!-- MID Column -->
                <ng-container matColumnDef="academy">
                    <mat-header-cell class="row rowfix bg-info table-header" *matHeaderCellDef mat-sort-header>Academy</mat-header-cell>>
                    <mat-cell *matCellDef="let element"> {{element.academy}} </mat-cell>
                  </ng-container>

                  <!-- MID Column -->
                <ng-container matColumnDef="submissionDate">
                    <mat-header-cell class="row rowfix bg-info table-header" *matHeaderCellDef mat-sort-header>Submission Date</mat-header-cell>>
                    <mat-cell *matCellDef="let element"> {{element.submissionDate}} </mat-cell>
                  </ng-container>

                  <!-- MID Column -->
                <ng-container matColumnDef="assignmentDueDate">
                    <mat-header-cell class="row rowfix bg-info table-header" *matHeaderCellDef mat-sort-header>Assignment Due</mat-header-cell>>
                    <mat-cell *matCellDef="let element"> {{element.assignmentDueDate}} </mat-cell>
                  </ng-container>

                <!-- SubStatus Column -->
                <ng-container matColumnDef="subStatus">
                    <mat-header-cell class="row rowfix bg-info table-header" *matHeaderCellDef mat-sort-header>Sub Status</mat-header-cell>>
                    <mat-cell *matCellDef="let element"> {{element.subStatus}} </mat-cell>
                  </ng-container>
              
                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
       
    </div>
</section>    

<section>
    <div>
      <!-- <h1 class="col-md-12 display-4 text-secondary text-center" style="min-height: 300px;" *ngIf="requestList.length == 0">NO DATA FOUND</h1> -->
       <button type="button" class="btn btn-primary" (click)="downloadSelectedRequests()">Download Selected</button>
      &nbsp;&nbsp;
      <button type="button" class="btn btn-primary" (click)="exportAllRecords()">Export All</button>
      &nbsp;&nbsp;
      <button type="button" class="btn btn-primary" (click)="exportFilteredRecords()">Export Filtered</button>
      &nbsp;&nbsp;
      <button type="button" class="btn btn-primary" (click)="clearFilter()">Clear Filter</button>
      &nbsp; &nbsp;
      <mat-paginator class="mat-paginator" [pageSizeOptions]="[3, 4]" showFirstLastButtons></mat-paginator>
 </div>

</section>