<mat-dialog-content class="popupDialog" style="overflow:none !important">
  <div class="closePopupDialog">
    <button mat-button mat-icon-button (click)="closePopup()">
      <mat-icon>close</mat-icon>
    </button>
  </div>
  <div class="headerText">{{message}}</div>
  <div class="downloadButtonDiv">
    <button class="downloadButton" mat-raised-button aria-label="Go" [disabled]="isDateInvalid" (click)="closePopup()">
      <i class="spriteIcons floatRight resetAllIcon"></i>
      Ok
    </button>
  </div>


</mat-dialog-content>