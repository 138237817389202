<mat-dialog-content class="popupDialog" style="overflow:none !important">
  <div class="closePopupDialog">
    <button mat-button mat-icon-button (click)="closePopup()">
      <mat-icon>close</mat-icon>
    </button>
  </div>
  <div class="popupHeader">Download Report</div>
  <div class="headerText">Please select the evaluation date range</div>

  <div class="font12 floatLeft fromDate">
    <div class="textStyle">From</div>
      <div class="matForm">
        <div class="dateDiv">
          <input
            [matDatepicker]="iFromDate"
            placeholder="Choose a Date"
            (dateInput)="fromDateSelected($event)"
            (dateChange)="fromDateSelected($event)"
            class="certDate"
            [(ngModel)] = "selectedFromDate"
            [max]="maxDate"
            [min]="minDate"
            readonly/>
          </div>              
          <mat-datepicker matInput #iFromDate ></mat-datepicker>
      <i class="spriteIcons floatLeft calendarIcon mousePointer" (click)="openCalendar(iFromDate)"
      matTooltipClass="tooltipStyle"
      matTooltip="Click to pick a date"></i>
      </div>
  </div>
  <div class="toParentDiv">
      <div class="textStyle">To</div>
        <div class="matForm">
          <div class="dateDiv">
            <input 
              [matDatepicker]="iToDate"
              placeholder="Choose a Date"
              (dateInput)="toDateSelected($event)"
              (dateChange)="toDateSelected($event)"
              class="certDate"
              [(ngModel)] = "selectedEndDate"
              [max]="maxDate"  
              [min]="minDate"               
              readonly/>
          </div>
            <mat-datepicker #iToDate></mat-datepicker>
          <i class="spriteIcons floatLeft calendarIcon mousePointer" (click)="openCalendar(iToDate)" 
          matTooltipClass="tooltipStyle"
          matTooltip="Click to pick a date"></i>
        </div>
  </div>
  <div class="errorMessage" *ngIf="isDateInvalid">{{errorText}}</div>
  <div class="message" *ngIf="isDownloaded">{{message}}</div>
  <div class="downloadButtonDiv"> 
  <button class="downloadButton" mat-raised-button aria-label="Go"
   [disabled]="isDateInvalid" (click)="downloadReport()">
    <i class="spriteIcons floatRight resetAllIcon"></i>
    Download
  </button>
</div>


</mat-dialog-content>