import { Component, OnInit, Input } from '@angular/core';
import { requestDetails } from 'src/app/models/requestDetails';
import { LogDetails } from 'src/app/models/LogDetails';
import { RequestService } from 'src/app/global-component/services/api-mapping-services/request.service';


@Component({
  selector: 'app-log',
  templateUrl: './log.component.html',
  styleUrls: ['./log.component.css']
})
export class LogComponent implements OnInit{
  @Input() public logInput: any;
  public logDetails: LogDetails;
  constructor(private _requestService: RequestService) {

  }
  public ngOnInit() {
    console.log('logData', this.logInput);
      this._requestService.loadLogData(this.logInput.EvaluationRequestType, this.logInput.EvaluationRequestId).subscribe(data => {
        this.logDetails = data as LogDetails;
        console.log('LogData', this.logDetails);
    });
  }
}

