import { ModuleWithProviders } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomeComponent } from './components/home/home.component';
import { NotFoundComponent } from './components/not-found/not-found.component';
import { AuthGuard } from './global-component/guards/auth-guard.service';
import { ROLES_CONST } from './models/Constants/ROLES_CONST';
import { LoginComponent } from './login/login.component';
import { MyRequestListComponent } from './modules/request/my-request-list/my-request-list.component';
import { MsalGuard } from '@azure/msal-angular';
import { RequestdetailsComponent } from './modules/request/requestdetails/requestdetails.component';

const routes: Routes = [
	{
		path: '',
		children: [
			{
				path: '',
				component: MyRequestListComponent,
				canActivate: [MsalGuard]
			},
			{
				path: 'home',
				component: MyRequestListComponent,
				canActivate: [MsalGuard]
			},
			{
				path: 'login',
				component: LoginComponent,
				canActivate: [AuthGuard]
			},
			{
				path: 'request/details/:requestId/:evaluationRequestType',
				component: RequestdetailsComponent
			}

		]
	}
];

export const routing: ModuleWithProviders<any> = RouterModule.forRoot(routes);
