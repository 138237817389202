import { Injectable, OnInit } from '@angular/core';
import * as Msal from "@azure/msal-browser";
import { MsalService } from '@azure/msal-angular';
import { StorageHelperService } from '../services/wrappers/storage-helper.service';
import { authConfig } from './Config';
@Injectable({
	providedIn: 'root'
})
export class AuthService implements OnInit {
	public redirectUri: string;
	private clientApplication: Msal.PublicClientApplication;

	constructor(
    private storageService: StorageHelperService,
    private msalService:MsalService
    ) {
		/* this.clientApplication = new Msal.PublicClientApplication({
			auth: {
				clientId: authConfig.clientID,
				authority: authConfig.authority,
				redirectUri: authConfig.redirectUri,
			},
			cache: {
				cacheLocation: "localStorage", // This configures where your cache will be stored
				storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
			}
		}); */

	}

	public ngOnInit() {
		this.redirectUri = '';
	}

	public login(): void {
		//this.clientApplication.loginRedirect(authConfig.scopes);

		this.clientApplication.loginRedirect();
	}

	public logout(): void {
		//this.clientApplication.logout();
    this.msalService.logout();
		localStorage.clear();
	}

	public isOnline(): boolean {
		const currentAccount = this.clientApplication.getAllAccounts();
		if (currentAccount.length === 1) {
			return true;
		}
		else
			return false;
	}

	public getUser(): Msal.AccountInfo {
		const currentAccount = this.clientApplication.getAllAccounts();
		if (currentAccount.length === 1) {
			return currentAccount[0];
		}
	}

	get getAuthTokenFromStorage() {
		return this.storageService.getItemFromLocal('msal.idtoken');
	}

	// public getAuthenticationToken(): Promise<string> {
	// 	return this.clientApplication
	// 		.acquireTokenSilent(authConfig.scopes)
	// 		.then(token => {
	// 			console.log('Got silent access token: ', token);
	// 			return token;
	// 		})
	// 		.catch(error => {
	// 			console.log('Could not silently retrieve token from storage.', error);
	// 			return this.clientApplication
	// 				.acquireTokenPopup(authConfig.scopes)
	// 				.then(token => {
	// 					console.log('Got popup access token: ', token);
	// 					return Promise.resolve(token);
	// 				})
	// 				.catch(innererror => {
	// 					console.log('Could not retrieve token from popup.', innererror);
	// 					return Promise.resolve('');
	// 				});
	// 		});
	// }

	private authCallback(errorDesc: any, token: any, error: any, tokenType: any) {
		console.log('auth callback');
		if (token) {
			console.log('Got token', token);
		} else {
			console.log(error + ':' + errorDesc);
		}
	}
}
