import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
	providedIn: 'root'
})
export class DownloadHelperService {
	constructor(private http: HttpClient) { }

	public DownloadFile(Url: string) {
		this.getUrlWithSASUri(Url).then(data => {
			let decodedUri = decodeURIComponent(escape(window.atob(data)));
			window.open(decodedUri, "blank");
		})


		// const link = document.createElement('a');
		// link.href = Url;
		// link.target = "_blank";
		// link.click();

	}
	public getUrlWithSASUri(blobUrl) {
		//let httpParams = new HttpParams().set('blobUrl', blobUrl);
		return this.http.post<string>(environment.apiUrl + '/User/GetUrlWithSASUri', {​"blobUrl":blobUrl}).toPromise();
	}
	public getUrlWithSASUriForReportOrMail(blobUrl) {
		let httpParams = new HttpParams()
			.set('blobUrl', blobUrl);
		return this.http.get<string>(environment.apiUrl + '/User/GetUrlWithSASUriForReportOrMail', { params: httpParams }).toPromise();
	}
}
