import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { RequestView } from 'src/app/models/RequestView';
import { RequestSearchFilter } from 'src/app/models/SearchFilter';
import { RequestService } from 'src/app/global-component/services/api-mapping-services/request.service';
import { MatDialogConfig, MatDialog } from '@angular/material/dialog';
import { MatTableDataSource, MatTable, MatTableModule } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { SelectionModel } from '@angular/cdk/collections';
import * as XLSX from 'xlsx';
import { DownloadHelperService } from 'src/app/global-component/services/helpers/DownloadHelperService/downloadhelper.service';
import { responseStatus } from 'src/app/models/responseStatus';
import { RequestlistComponent } from '../requestlist/requestlist.component';
import { FormControl } from '@angular/forms';
import { ArrayPropertyFilterPipe } from 'src/app/array-property-filter.pipe';
import { StorageHelperService } from 'src/app/global-component/services/wrappers/storage-helper.service';
import { Router } from '@angular/router';
import { IUser } from 'src/app/models/User.model';
import { UserService } from 'src/app/global-component/services/api-mapping-services/user.service';
import { AppService } from 'src/app/app.service';
import { stringify } from 'querystring';
import { filter } from 'rxjs/operators';
import { authConfig } from 'src/app/global-component/authentication/Config';
import { DownloadReportPopupComponent } from 'src/app/ui-component/custom-ui-components/modals/download-report-popup/download-report-popup.component';
import { MessageDialogComponent } from 'src/app/ui-component/custom-ui-components/modals/message-dialog/message-dialog.component';
import { DatePipe } from '@angular/common';
import { MsalBroadcastService, MsalService } from '@azure/msal-angular';
import { EventMessage, EventType, InteractionStatus } from '@azure/msal-browser';
import { HttpClient } from '@angular/common/http';
const GraphEndPoint = "https://graph.microsoft.com/v1.0/me";
type ProfileType = {
	givenName?: string,
	surname?: string,
	userPrincipalName?: string,
	id?: string
};

@Component({
	selector: 'app-my-request-list',
	templateUrl: './my-request-list.component.html',
	styleUrls: ['./my-request-list.component.css']
})
export class MyRequestListComponent implements OnInit {

	profile!: ProfileType;
	public requestList: RequestView[] = [];
	public pageCount: number;
	public records: number;
	public searchFilter = new RequestSearchFilter();
	public checkboxes: boolean[] = [];
	public selectedList: string[] = [];
	public isAllSelected = false;
	public sourceData: MatTableDataSource<RequestView>;
	@ViewChild(MatSort, { static: false }) sort: MatSort;
	@ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
	public subStatusList: string[] = [];
	public allRequestIds: number[] = [];
	public user: IUser;
	public mid: string;
	public isSourceDataAvailable: boolean;
	isResetButtonDisabled: boolean = true;
	isDownloadSelectedDisabled: boolean = true;
	radioButtonValue: string = "Pending";
	selectCheckBox: boolean = false;
	public selectedRequestIds: number[] = [];
	evalRequestId: number = -1;
	requestFilterValue: string = "";
	searchText: string = "";
	filterPipe = new ArrayPropertyFilterPipe();
	initialSelection = [];
	allowMultiSelect = true;
	masterSelected: boolean = false;
	showInput: boolean = false;
	datePipe = new DatePipe('en-US');
	loginDisplay = false;
	selection = new SelectionModel<RequestView>(true, []);

	displayedColumns: string[] = ['select', 'requestId', 'courseName', 'learnerName', 'learnerId',
		'academy', 'submissionDate', 'slaHitDate', 'evluationRequestType', 'buttons'];

	constructor(private requestService: RequestService,
		private downloadHelperService: DownloadHelperService,
		private storageHelperService: StorageHelperService,
		private router: Router,
		private storageService: StorageHelperService,
		private userService: UserService,
		private appService: AppService,
		private _popup: MatDialog,
		private authService: MsalService,
		private msalBroadcastService: MsalBroadcastService,
		private http: HttpClient) { }

	ngOnInit() {
		this.msalBroadcastService.msalSubject$
			.pipe(
				filter((msg: EventMessage) => msg.eventType === EventType.LOGIN_SUCCESS),
			)
			.subscribe((result: EventMessage) => {
				console.log(result);
			});
		this.msalBroadcastService.inProgress$
			.pipe(
				filter((status: InteractionStatus) => status === InteractionStatus.None)
			)
			.subscribe(() => {
				this.setLoginDisplay();
			});
		this.getProfile();

		this.loadRequestList();
		this.radioButtonValue = "Pending";
		if (this.sourceData != null && this.sourceData.filteredData.length == 0)
			this.isSourceDataAvailable = false;
		else
			this.isSourceDataAvailable = true;
	}
	getProfile() {
		this.http.get(GraphEndPoint)
			.subscribe(profile => {
				this.profile = profile;
				console.log('this.', this.profile);
			});
	}

	setLoginDisplay() {
		this.loginDisplay = this.authService.instance.getAllAccounts().length > 0;
	}

	public loadRequestList() {

		// if(storageUserString ==  null) {
		this.userService.getCurrentUser().subscribe(data => {
			var storageUserString = this.storageService.getItemFromLocal('user');
			console.log('hittt', data, storageUserString);
			if (storageUserString != null) {

				var storageUserString = this.storageService.getItemFromLocal('user');
				console.log('storage', storageUserString);
				//this.user = storageUserString as unknown as IUser;
				this.user = JSON.parse(storageUserString);
				this.mid = this.user.mid;
				console.log('Usersss', this.mid, this.user);
				if (authConfig.redirectUri != "https://apsis.mindtree.com/") {
					console.log('Hit');
					this.appService.checkAccessForEvaluators(this.mid).then((response: any) => {
						if (!response) {
							console.log('reponse', response);
							window.location.href = 'https://apsis.mindtree.com/';
						}
					});
				}
				this.requestService.getRequestsFiltered(this.mid).subscribe(
					data => {
						this.requestList = data as RequestView[];

						if (this.requestList != null && this.requestList.length == 0)
							this.isSourceDataAvailable = false;
						else
							this.isSourceDataAvailable = true;
						console.log('Requestlist', this.requestList);
						if (this.requestList != null && this.requestList.length > 0) {
							this.records = this.requestList[0].totalRows;
							this.pageCount = this.requestList[0].totalRows / this.searchFilter.PageSize;
							for (let i = 0, len = this.records; i < len; i++) {
								this.checkboxes[i] = false;
								console.log('checkbox initialized: ' + this.checkboxes[i]);
							}
							this.isAllSelected = false;
							this.selectedList = [];
							this.sourceData = new MatTableDataSource(this.requestList);
							this.onRadioButtonChange('Pending', false);
							console.log('SourceData', this.sourceData);
							this.sourceData.sort = this.sort;
							this.sourceData.paginator = this.paginator;
							for (let i = 0; i < this.requestList.length; i++) {
								this.allRequestIds[i] = this.requestList[i].evaluationRequestId;
							}
						} else {
							this.requestList = [];
							this.checkboxes = [];
							this.pageCount = 0;
							this.isAllSelected = false;
							this.selectedList = [];
							this.records = 0;
						}
					},
					error => {
						this.requestList = [];
						this.checkboxes = [];
						this.pageCount = 0;
						this.isAllSelected = false;
						this.selectedList = [];
						this.records = 0;
					});
			}
		});



		//}
		console.log('user', this.user);
		//this.user = JSON.parse(storageUserString);

	}

	onRadioButtonChange(value: string, isRequestFilter: boolean = false) {
		if (isRequestFilter == false)
			this.requestFilterValue = "";
		this.masterSelected = false;
		for (let i = 0; i < this.requestList.length; i++) {
			this.requestList[i].checked = false;
		}
		this.selectedRequestIds.length = 0;
		this.radioButtonValue = value;
		this.selection.clear();
		console.log('value', value);
		if (value == 'Completed') {
			this.subStatusList = [];
			var filteredList = this.requestList.filter(item => item.isEvaluated == true);
			this.sourceData = new MatTableDataSource<RequestView>(filteredList);
			if (this.sourceData.filteredData.length == 0)
				this.isSourceDataAvailable = false;
			else
				this.isSourceDataAvailable = true;
			console.log('completed', filteredList);
			for (let i = 0; i < filteredList.length; i++) {
				this.subStatusList[i] = filteredList[i].subStatus;
			}
			var tempSubStatusList = this.subStatusList;
			this.subStatusList = tempSubStatusList.filter(function (item, pos) {
				return tempSubStatusList.indexOf(item) == pos;
			});
		}
		else if (value == 'Pending') {
			this.subStatusList = [];
			var filteredList = this.requestList.filter(item => item.isEvaluated == false && item.subStatus != null && item.subStatus.includes('Result awaited') && item.solutionFile != null);
			this.sourceData = new MatTableDataSource<RequestView>(filteredList);
			if (this.sourceData.filteredData.length == 0)
				this.isSourceDataAvailable = false;
			else
				this.isSourceDataAvailable = true;
			for (let i = 0; i < filteredList.length; i++) {
				this.subStatusList[i] = filteredList[i].subStatus;
			}
			var tempSubStatusList = this.subStatusList;
			this.subStatusList = tempSubStatusList.filter(function (item, pos) {
				return tempSubStatusList.indexOf(item) == pos;
			});
		}
		setTimeout(() => {
			this.sourceData.sort = this.sort;
			this.sourceData.paginator = this.paginator;
		});
	}

	selectCheckBoxChanged(value: RequestView) {
		if (value.checked == false)
			this.selectCheckBox = true;
		else
			this.selectCheckBox = false;
		console.log('bool value', value);
	}

	applyFilter(filterValue: string) {
		console.log('fa', filterValue.length);
		if (filterValue.length > 0)
			this.isResetButtonDisabled = false;
		else if (filterValue.length == 0)
			this.isResetButtonDisabled = true;
		this.sourceData.filter = filterValue.trim().toLowerCase();
		if (this.sourceData.filteredData.length == 0)
			this.isSourceDataAvailable = false;
		else
			this.isSourceDataAvailable = true;
	}

	masterToggle() {
		if (this.isAllCheckBoxSelected()) {
			this.selectedRequestIds = [];
			this.selection.clear();
			for (let i = 0; i < this.requestList.length; i++) {
				this.requestList[i].checked = false;
			}
			this.isDownloadSelectedDisabled = true;
		}
		else {
			this.isDownloadSelectedDisabled = false;
			this.sourceData.data.forEach(row => this.selection.select(row));
			var requestIds = [];
			var filteredData = this.sourceData.filteredData;
			for (let i = 0; i < filteredData.length; i++) {
				requestIds[i] = filteredData[i].evaluationRequestId;
			}
			this.selectedRequestIds = requestIds;
			for (let i = 0; i < this.requestList.length; i++) {
				this.requestList[i].checked = true;
			}
		}
	}

	isAllCheckBoxSelected() {
		const numSelected = this.selectedRequestIds.length;
		const numRows = this.sourceData.filteredData.length;
		return numSelected == numRows;
	}

	public toggleInSelectedRequest(evaluationRequestId: string) {
		this.evalRequestId = evaluationRequestId as unknown as number;
		const index = this.selectedRequestIds.indexOf(this.evalRequestId);
		const value = this.requestList.find(x => x.evaluationRequestId === this.evalRequestId);
		if (index === -1) {
			console.log(evaluationRequestId + ' added');
			this.selectedRequestIds.push(this.evalRequestId);
			value.checked = true;
		} else {
			this.selectedRequestIds.splice(index, 1);
			console.log(evaluationRequestId + ' removed');
			value.checked = false;
		}
		if (this.selectedRequestIds.length != this.sourceData.filteredData.length)
			this.masterSelected = false;
		else
			this.masterSelected = true;
		if (this.selectedRequestIds.length == 0) {
			this.isDownloadSelectedDisabled = true;
		}
		else {
			this.isDownloadSelectedDisabled = false;
		}
	}

	public async exportAllRecords() {
		if (this.sourceData.data.length == 0) {
			const dialogConfig = new MatDialogConfig();
			dialogConfig.disableClose = false;
			dialogConfig.autoFocus = true;
			dialogConfig.panelClass = 'confirmDialogContainer';
			dialogConfig.data = "No Records available to export";
			const dialogRef = this._popup.open(MessageDialogComponent, dialogConfig);
		}
		else if (this.sourceData.data.length > 0) {
			await this.getSasUrlForSolutionLink('All');
			var report = this.sourceData.data.map(x => {
				return {
					'Request Id': x.requestId,
					'Evaluation Request Id': x.evaluationRequestId,
					'Learner Name': x.learnerName,
					'Learner Id': x.learnerId,
					'Course Name': x.courseName,
					'Academy': x.academy,
					'Submission Date': this.datePipe.transform(x.submissionDate, 'MM/dd/yyyy'),
					'Evaluation Date': this.datePipe.transform(x.evaluationDate, 'MM/dd/yyyy'),
					'Attempts': x.attempts,
					'SLA Hit Date': this.datePipe.transform(x.slaHitDate, 'MM/dd/yyyy'),
					'Has SLA Passed': x.isSLAPassed == true ? 'Yes' : 'No',
					'Is Evaluated': x.isEvaluated,
					'Sub Status': x.subStatus,
					'Evaluation Request Type': x.evluationRequestType,
					'Solution': x.solutionFile,
					'Result': x.result == null ? 'NA' : x.result,
					'Passing Score': x.passingScore,
					'Max Score': x.maxScore
				}
			});
			const ws = XLSX.utils.json_to_sheet(report);
			const wb: XLSX.WorkBook = XLSX.utils.book_new();
			XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

			// save to file
			XLSX.writeFile(wb, 'EvaluationRequests.xlsx');
		}
	}

	downloadEvaluationReport() {
		console.log('ta', this.user.mid);
		const dialogConfig = new MatDialogConfig();
		dialogConfig.disableClose = false;
		dialogConfig.autoFocus = true;
		dialogConfig.panelClass = 'confirmDialogContainer';
		dialogConfig.data = this.user.mid;
		const dialogRef = this._popup.open(DownloadReportPopupComponent, dialogConfig);
	}

	public async exportFilteredRecords() {
		if (this.sourceData.filteredData.length == 0) {
			const dialogConfig = new MatDialogConfig();
			dialogConfig.disableClose = false;
			dialogConfig.autoFocus = true;
			dialogConfig.panelClass = 'confirmDialogContainer';
			dialogConfig.data = "No Records available to export";
			const dialogRef = this._popup.open(MessageDialogComponent, dialogConfig);
		}
		else if (this.sourceData.filteredData.length > 0) {

			await this.getSasUrlForSolutionLink('Filtered');
			var report = this.sourceData.filteredData.map(x => {
				return {
					'Request Id': x.requestId,
					'Evaluation Request Id': x.evaluationRequestId,
					'Learner Name': x.learnerName,
					'Learner Id': x.learnerId,
					'Course Name': x.courseName,
					'Academy': x.academy,
					'Submission Date': this.datePipe.transform(x.submissionDate, 'MM/dd/yyyy'),
					'Evaluation Date': this.datePipe.transform(x.evaluationDate, 'MM/dd/yyyy'),
					'Attempts': x.attempts,
					'SLA Hit Date': this.datePipe.transform(x.slaHitDate, 'MM/dd/yyyy'),
					'Has SLA Passed': x.isSLAPassed == true ? 'Yes' : 'No',
					'Is Evaluated': x.isEvaluated,
					'Sub Status': x.subStatus,
					'Evaluation Request Type': x.evluationRequestType,
					'Solution': x.solutionFile,
					'Result': x.result == null ? 'NA' : x.result,
					'Passing Score': x.passingScore,
					'Max Score': x.maxScore
				}
			});
			const ws = XLSX.utils.json_to_sheet(report);
			const wb: XLSX.WorkBook = XLSX.utils.book_new();
			XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

			// save to file
			XLSX.writeFile(wb, 'EvaluationRequests.xlsx');
		}

	}
	async getSasUrlForSolutionLink(type) {
		if (type == 'Filtered') {
			for (let i = 0; i < this.sourceData.filteredData.length; i++) {
				await this.downloadHelperService.getUrlWithSASUriForReportOrMail(this.sourceData.filteredData[i].solutionFile).then(data => {
					let decodedUri = decodeURIComponent(escape(window.atob(data)));
					this.sourceData.filteredData[i].solutionFile = decodedUri;
				});
			}
		}
		else {
			for (let i = 0; i < this.sourceData.data.length; i++) {
				await this.downloadHelperService.getUrlWithSASUriForReportOrMail(this.sourceData.data[i].solutionFile).then(data => {
					let decodedUri = decodeURIComponent(escape(window.atob(data)));
					this.sourceData.data[i].solutionFile = decodedUri;
				});
			}
		}
	}

	public downloadAssignment(requestId: string, FileUrl: string) {
		if (FileUrl != null && FileUrl.length > 0) {
			this.downloadHelperService.DownloadFile(String(FileUrl));
		} else {
			const dialogConfig = new MatDialogConfig();
			dialogConfig.disableClose = false;
			dialogConfig.autoFocus = true;
			dialogConfig.panelClass = 'confirmDialogContainer';
			dialogConfig.data = "No File Available";
			const dialogRef = this._popup.open(MessageDialogComponent, dialogConfig);
		}
	}

	public downloadSelectedRequests() {
		var requestIds = this.selectedRequestIds;
		console.log('CLICKED : ', requestIds);

		for (let i = 0; i < requestIds.length; i++) {
			var solution = this.requestList.find(x => x.evaluationRequestId == requestIds[i]).solutionFile;
			if (solution != null)
				this.downloadHelperService.DownloadFile(solution);
		}
		if (requestIds.length == 0) {
			const dialogConfig = new MatDialogConfig();
			dialogConfig.disableClose = false;
			dialogConfig.autoFocus = true;
			dialogConfig.panelClass = 'confirmDialogContainer';
			dialogConfig.data = "Please Select any Request";
			const dialogRef = this._popup.open(MessageDialogComponent, dialogConfig);
		}
	}

	public applyRequestFilter(event) {
		this.isResetButtonDisabled = false;
		this.requestFilterValue = event;
		this.onRadioButtonChange(this.radioButtonValue, true);
		var filteredValues = this.filterPipe.transform(this.sourceData.data, { property: 'evluationRequestType', flag: this.requestFilterValue });
		this.sourceData = new MatTableDataSource<RequestView>(filteredValues);
		this.subStatusList = [];
		if (this.sourceData.filteredData.length == 0)
			this.isSourceDataAvailable = false;
		else
			this.isSourceDataAvailable = true;
		for (let i = 0; i < filteredValues.length; i++) {
			this.subStatusList[i] = filteredValues[i].subStatus;
		}
		this.sourceData.paginator = this.paginator;
		this.sourceData.sort = this.sort;
	}

	public resetAllFilters() {
		this.isResetButtonDisabled = true;
		this.masterSelected = false;
		this.searchText = "";
		this.requestFilterValue = "";
		this.loadRequestList();
		this.radioButtonValue = "pending";
		if (this.sourceData.filteredData.length == 0)
			this.isSourceDataAvailable = false;
		else
			this.isSourceDataAvailable = true;
		for (let i = 0; i < this.requestList.length; i++) {
			this.requestList[i].checked = false;
		}
	}

	public getRecord(evaluationRequestId: string, evaluationRequestType: string) {
		console.log("row clicked", evaluationRequestId);
		console.log('Select RequestId (on click): ' + evaluationRequestType);
		const key = 'requestId';
		const filterKey = 'filterKey';
		this.storageHelperService.setItemToLocal(key, evaluationRequestId);
		this.storageHelperService.setItemToLocal(filterKey, evaluationRequestType);
		this.router.navigate(['request/details', evaluationRequestId, evaluationRequestType]);
	}
}
