import { Component, Inject, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { AppService } from "./app.service";
import { UserService } from "./global-component/services/api-mapping-services/user.service";
import { FlyerService } from "./global-component/services/wrappers/flyer.service";
import { IUser } from "./models/User.model";
import { AuthService } from "./global-component/authentication/auth.service";
import { ROLES_CONST } from "./models/Constants/ROLES_CONST";
import { StorageHelperService } from "./global-component/services/wrappers/storage-helper.service";
import { SwUpdate } from "@angular/service-worker";
import { MsalBroadcastService, MsalGuardConfiguration, MsalService, MSAL_GUARD_CONFIG } from "@azure/msal-angular";
import { InteractionStatus, RedirectRequest } from "@azure/msal-browser";
import { filter, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.css"]
})
export class AppComponent implements OnInit {
  userNameFromGraphDetails: string;
  userImageFromGraphDetails: any;
  userImage: ImageData;
  loginDisplay = false;
  private readonly _destroying$ = new Subject<void>();
  constructor(
    private userService: UserService,
    private appService: AppService,
    private authService: AuthService,
    private storageService: StorageHelperService,
    private router: Router,
    private swUpdate: SwUpdate,
    private authorizationService: MsalService,
    private broadcastService: MsalBroadcastService,
    @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration
  ) {}

  public ngOnInit() {
    /* if (this.swUpdate.isEnabled) {
      this.swUpdate.available.subscribe(() => {
        console.log("appcomp,sw,new");
        window.location.reload(true);
      });
    }*/
    // this.userService.getCurrentUser().subscribe(data => {
    //   if (data != null) {
    //     const user = data as IUser;
    //     //user.roleId = ROLES_CONST.Evaluator;
    //     this.appService.setUser(user);
    //   } else {
    //     this.authService.login();
    //   }
    // }); 
    this.router.navigate(["home"]);
    this.broadcastService.inProgress$
    .pipe(
      filter((status: InteractionStatus) => status === InteractionStatus.None),
      takeUntil(this._destroying$)
    )
    .subscribe(() => {
      this.setLoginDisplay();
    })
  }
  login() {
    if (this.msalGuardConfig.authRequest){
      this.authorizationService.loginRedirect({...this.msalGuardConfig.authRequest} as RedirectRequest);
    } else {
      this.authorizationService.loginRedirect();
    }
  }

  setLoginDisplay() {
    if(this.authorizationService.instance.getAllAccounts().length>0) {     
      const currentUser = this.authorizationService.instance.getAllAccounts()[0];
      var mid = currentUser.username.substring(0, currentUser.username.indexOf('@'));
      let user : IUser = {name: currentUser.name, mid: mid};      
      if (currentUser.username.includes('ltimindtree.com')) {
        let ps_number = mid.split('.')[1];
        if (ps_number != '') {
          user.mid = ps_number;
        }
      }
      console.log('f', user);
      this.appService.setUser(user);
    }
  }
  ngOnDestroy(): void {
    this._destroying$.next(undefined);
    this._destroying$.complete();
  }
}
