<div class="firstDiv">
  <div class="buttonsAndDropDownDiv">
    <div class="floatLeft">
        <input matInput (keyup)="applyFilter($event.target.value)" [(ngModel)]="searchText" class="searchBar" placeholder="Search">
    </div>

    <div class="rightPaneDiv">
      <div class="resetButtonDiv">
        <button class="resetAllButton" mat-raised-button aria-label="Go" (click)="resetAllFilters()"
          [disabled]="isResetButtonDisabled">
          <i class="spriteIcons floatRight resetAllIcon"></i>
          Reset All Filters
        </button>
      </div>
    
      <div>
        <select class="filterDropDown" [(ngModel)] = "requestFilterValue" (change)="applyRequestFilter($event.target.value)">
          <option value="" hidden>Filter by Request</option>
          <option value="Assignment">Assignment</option>
          <option value="Project">Project</option>
        </select>
      </div>
<!--     
      <div>
        <select class="filterDropDown" [(ngModel)] = "subStatusFilterValue" (change)="applySubStatusFilter($event.target.value)">
          <option value="" hidden>Filter by Sub-status</option>
          <option *ngFor="let subStatus of subStatusList; let i = index" [value]="subStatusList[i]">
              {{subStatusList[i]}}
          </option>
        </select> 
      </div> -->
    
      <div class="requestTypeRadioButtons">
        <input type="radio" class="pendingRadioButton" name="requestType" value="pending" 
          (change)="onRadioButtonChange('Pending')"
          [checked]="radioButtonValue == 'Pending'">Pending
        <input type="radio" class="completedRadioButton" name="requestType" value="completed"
          (change)="onRadioButtonChange('Completed')"
          [checked]="radioButtonValue == 'Completed'">Completed
      </div>
    </div>
  </div>

  <div *ngIf = 'isSourceDataAvailable'>
    <!-- <mat-table [dataSource]="sourceData" class="mat-elevation-z8">
      <ng-container [matColumnDef]="column" *ngFor="let column of displayedColumns">
        <mat-header-cell *matHeaderCellDef> {{column}} </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{element[column]}} </mat-cell>
      </ng-container>
    
      <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumns ;"></mat-row>
    </mat-table> -->
    <mat-table [dataSource]="sourceData" class="tableStyle" matSort>
      <ng-container matColumnDef="select">
        <mat-header-cell class="checkBoxHeader" *matHeaderCellDef>
          <mat-checkbox (change)="masterToggle()"
                  [(ngModel)] = "masterSelected">
          </mat-checkbox>
        </mat-header-cell>
        <mat-cell class="checkBoxRows" *matCellDef="let row">
          <mat-checkbox (click)="$event.stopPropagation()"
                        (change)="toggleInSelectedRequest(row.evaluationRequestId)"
                        [checked]="selection.isSelected(row)"
                        [(ngModel)]="row.checked">
          </mat-checkbox>
        </mat-cell>
      </ng-container>      
      
      <ng-container matColumnDef="requestId">
        <mat-header-cell class="requestIdHeader" mat-sort-header *matHeaderCellDef>Request Id</mat-header-cell>
        <mat-cell *matCellDef="let element" class="mousePointer"
          (click)="getRecord(element.evaluationRequestId, element.evluationRequestType)">{{element.requestId}}</mat-cell>
      </ng-container>
  
      <ng-container matColumnDef="courseName">
        <mat-header-cell class="courseNameHeader" mat-sort-header *matHeaderCellDef>Course Name</mat-header-cell>
        <mat-cell *matCellDef="let element"> {{element.courseName}} </mat-cell>
      </ng-container>
    
      <!-- Name Column -->
      <ng-container matColumnDef="learnerName">
        <mat-header-cell class="learnerNameHeader" mat-sort-header *matHeaderCellDef>Learner's Name</mat-header-cell>
        <mat-cell *matCellDef="let element"> {{element.learnerName}} </mat-cell>
      </ng-container>
  
      <ng-container matColumnDef="learnerId">
        <mat-header-cell class="midHeader" mat-sort-header *matHeaderCellDef>MID</mat-header-cell>
        <mat-cell *matCellDef="let element">{{element.learnerId}}</mat-cell>
      </ng-container>
    
      <!-- Name Column -->
      <ng-container matColumnDef="academy">
        <mat-header-cell class="academyHeader" mat-sort-header *matHeaderCellDef>Academy</mat-header-cell>
        <mat-cell *matCellDef="let element">{{element.academy}}</mat-cell>
      </ng-container>
  
      <ng-container matColumnDef="submissionDate">
        <mat-header-cell class="submissionDateHeader" mat-sort-header *matHeaderCellDef>Submission Date</mat-header-cell>
        <mat-cell *matCellDef="let element">{{element.submissionDate | date : 'mediumDate'}}</mat-cell>
      </ng-container>
    
      <!-- Name Column -->
      <ng-container matColumnDef="slaHitDate">
        <mat-header-cell class="assignmentDateHeader" mat-sort-header *matHeaderCellDef>SLA Hit Date</mat-header-cell>
        <mat-cell *matCellDef="let element"> {{element.slaHitDate | date : 'mediumDate'}} </mat-cell>
      </ng-container>
  
      <!-- <ng-container matColumnDef="subStatus">
        <th mat-header-cell class="subStatusHeader" mat-sort-header *matHeaderCellDef>Sub-Status</th>
        <td mat-cell *matCellDef="let element">{{element.subStatus}}</td>
      </ng-container> -->
    
      <!-- Name Column -->
      <ng-container matColumnDef="evluationRequestType">
        <mat-header-cell class="requestTypeHeader" mat-sort-header *matHeaderCellDef>Evaluation Request Type</mat-header-cell>
        <mat-cell *matCellDef="let element">{{element.evluationRequestType}}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="buttons">
        <mat-header-cell class="buttonsHeader" *matHeaderCellDef></mat-header-cell>
        <mat-cell *matCellDef="let element">
          <div class="spriteIcons downloadIcon floatLeft mousePointer" 
          matTooltipClass="tooltipStyle" matTooltip="Click here to download solution"
            (click)="downloadAssignment(element.requestId,element.solutionFile)"></div>
          <div *ngIf="element.isSLAPassed" class="spriteIcons errorIcon"></div>
        </mat-cell>
      </ng-container>
  
      <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
      <mat-row [ngClass]="{'highlight' : row.checked}" *matRowDef="let row; columns: displayedColumns;"></mat-row>
    </mat-table>
  </div>

  <div class = 'noRequests' *ngIf = '!isSourceDataAvailable'>
    No Requests Found
  </div>

 
  

  <div class="buttonsAndPaginator">
    <div>
      <button class="buttons" mat-raised-button aria-label="Go" [disabled]="isDownloadSelectedDisabled"
                (click)="downloadSelectedRequests()">
                <i class="spriteIcons downloadWhiteIcon"></i>
                Download Selected
      </button>    
    </div>
    <div>
      <button class="buttons" mat-raised-button aria-label="Go" 
                (click)="exportAllRecords()">
                <i class="spriteIcons"></i>
                Export All
      </button>
    </div>
    <div>
      <button class="buttons" mat-raised-button aria-label="Go" [disabled]="isResetButtonDisabled"
                (click)="exportFilteredRecords()">
                <i class="spriteIcons"></i>
                Export Filtered
      </button>
    </div>
    <div>
      <button class="buttons" mat-raised-button aria-label="Go" 
                (click)="downloadEvaluationReport()">
                <i class="spriteIcons"></i>
                Download Evaluation Report
      </button>
    </div>
    <mat-paginator class="mat-paginator" [pageSizeOptions]="[5, 10]" showFirstLastButtons></mat-paginator>
  </div>
</div>
