import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Evaluation } from 'src/app/models/Evaluation';
import { RequestSearchFilter } from 'src/app/models/SearchFilter';
import { environment } from 'src/environments/environment';
import { catchError } from 'rxjs/internal/operators/catchError';
import { EvaluationResult } from 'src/app/models/EvaluationResult';
import { fileURLToPath } from 'url';
import 'rxjs/add/operator/toPromise';
import 'rxjs/Rx';
import { ResultComponent } from 'src/app/modules/request/requestdetails/result/result.component';

/**
 * Route: /Request
 */

@Injectable({
  providedIn: 'root'
})
export class RequestService {
  graphUrl: any;
  httpErrorHandlerService: any;
  constructor(private http: HttpClient) { }

	/**
  * Route: /my-requests
  * Body: none.
  * Method to get requests for currently logged in learner.
  */
  public getLearnerRequests() {
    return this.http.get(`${environment.apiUrl}/request/my-requests`);
  }

	/**
  * Route: /
  * Body: none.
  * Method to get all requests based on current filter.
  */
  //public getRequestsFiltered(requestSearchFilter: RequestSearchFilter, isExport?:boolean) {
  public getRequestsFiltered(routerLink: string) {
    //if(isExport === true)
    //return this.http.post(`${environment.apiUrl}/request?IsExport=true`, {responseType: "blob"});
    return this.http.get(`${environment.apiUrl}/User/EvaluationRequests/${routerLink}`);
  }

	/**
  * Route: /assignment-solution
  * Body: requestId array containing Guids
  * Method: to download all assignments
  * @param requestIds
  */
  public downloadAssignmentSolution(requestIds: string[]) {
    return this.http.post(`${environment.apiUrl}/request/assignment-solution`, requestIds);
  }

	/**
  * Route: /{requestId}/resubmission-date
  * Body: newAssignmentDueDate in localstring to be update
  * Method: to update resubmision date against a requestId
  * @param requestId
  * @param newAssignmentDueDate
  */

  public putResubmissionDate(requestId: string, newAssignmentDueDate: string) {
    const stringified = JSON.stringify(newAssignmentDueDate);
    return this.http.put(`${environment.apiUrl}/request/${requestId}/resubmission-date`, stringified,
      { headers: new HttpHeaders({ "Content-Type": "application/json" }) });
  }

	/**
  * Route: /{id}/assignment-solution
  * Body: none
  * Method: to upload solution against a requestId
  * @param requestId
  * @param
  */
  public postAssignmentSolution(requestId: string, formWithFile: FormData) {
    return this.http.post(`${environment.apiUrl}/request/${requestId}/assignment-solution`, formWithFile);
  }

	/**
  * Route: /request-details/{requestId}
  * Body: none
  * Method: to get details of requestId
  * @param requestId
  */
  public getRequestDetails(evaluationRequestType: string, evaluationRequestId: number) {
    return this.http.get(`${environment.apiUrl}/User/DetailedEvaluationRequest/${evaluationRequestType}/${evaluationRequestId}`);
  }
  /*** Route: /{id}/error
   * Body: formData having files and comments
   * Method: to update error/scorecard file against a requestId
   * @param requestId
   * @param form
   * const httpOptions = {
   headers: new HttpHeaders({'Content-Type': 'application/json'})
 }
   */
  public uploadFile(evaluationRequestType: string, requestId: number, learnerMid: string, result: string, fd: FormData) {
    return this.http.post(`${environment.apiUrl}/User/Evaluation/Uploadfile/${evaluationRequestType}/${requestId}/${learnerMid}/${result}`, fd);
    
  }


  /**
    * Route: /{id}/evaluation
    * Body: none
    * Method: to update evaluation against a resultId
    * 
    * @param evaluation
    */
  public submitEvaluationResult(evaluation: EvaluationResult) {
    console.log('result', evaluation);
    return this.http.post(`${environment.apiUrl}/User/Evaluation/Submit`, evaluation);
  }

  public loadLogData(evaluationRequestType: string, evaluationRequestId: number) {
    return this.http.get(`${environment.apiUrl}/User/GetEvaluatorLogData/${evaluationRequestType}/${evaluationRequestId}`);
  }

  public getDataForDownloadEvaluationReport(mid: string, fromDate: Date, toDate: Date) {
    var from = fromDate.toISOString();
    var to = toDate.toISOString();
    return this.http.get<any>(`${environment.apiUrl}/User/GetEvaluationReportData/${mid}/${from}/${to}`);
  }


  /* public submitDetails(result: EvaluationResult, file): any {
    const form = new FormData();
    form.append("file", file, file.name);
    form.append("result", JSON.stringify(result));


    return this.http.post(`${environment.apiUrl}/User/Evaluation/Submit`, form).toPromise();
  } */



	/**
  * Route: /result-publish
  * Body: none
  * Method: to publish results for a list of requestIds
  * @param requestIds
  */
  public publishResult(requestIds: string[]) {
    return this.http.post(`${environment.apiUrl}/request/result-publish`, requestIds);
  }

	/**
  * Route: /{id}/evaluation
  * Body: none
  * Method: to update evaluation against a resultId
  * @param requestId
  * @param evaluation
  */
  public updateEvaluationResult(requestId: number, evaluation: FormData) {
    return this.http.put(`${environment.apiUrl}/request/${requestId}/evaluation`, evaluation);
  }

  /*** Route: /{id}/error
  * Body: formData having files and comments
  * Method: to update error scorecard against a requestId
  * @param requestId
  * @param form
  */
  public postErrorAsync(requestId: SVGAnimatedNumber, form: FormData) {
    return this.http.post(`${environment.apiUrl}/request/${requestId}/error`, form);
  }

  /**
* Route: /assignment-solution
* Body: requestId array containing Guids
* Method: to download all assignments
* @param requestIds
*/
  public UpdateAssignmentDownloadDate(requestIds: string[]) {
    return this.http.put(`${environment.apiUrl}/Request/DownloadDate`, requestIds);
  }
  /**
* Route: /requestdetails
* Body: Learner MIDA
* Method: to get the learner image
* @param learnerMid
*/
  getUserImage(MId): Observable<Blob> {

    const Url = 'https://graph.windows.net/mindtree.com/users'
      +
      '/' +
      MId +
      '@mindtree.com/thumbnailPhoto?api-version=1.6';
    return this.http
      .get(Url, { responseType: 'blob' })
      .pipe(catchError((error: any) => {
        return throwError(error)
      }));
  }
  public uploadToBlob(file, result): any {
    const fd = new FormData();
    fd.append("file", file, file.name);
    // fd.append("result",JSON.stringify(result));
    //const httpHeaders = new HttpHeaders().append('Content-Type', 'undefined');
    //  return this.http.post(`${environment.apiUrl}/User/Evaluation/Submit`,fd).toPromise();
    // return this.http.post()
    return this.http.post(`${environment.apiUrl}/User/Evaluation/Submit`, {
      'data': JSON.stringify(result),
      'file': fd
    }).toPromise();
  }






  public SendEmail() {
    console.log(`${environment.apiUrl}/User/SendEmail`);
    return this.http.get(`${environment.apiUrl}/User/SendEmail`);
  }



}
