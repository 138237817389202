import { Component, OnInit } from '@angular/core';

@Component({
	selector: 'app-file-not-found',
	templateUrl: './not-found.component.html',
	styleUrls: ['./not-found.component.css']
})
export class NotFoundComponent implements OnInit {
	constructor() {}

	ngOnInit() {}
}
