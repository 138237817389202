import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { RequestComponent } from './request/request.component';
import { RequestlistComponent } from 'src/app/modules/request/requestlist/requestlist.component';
import { RequestdetailsComponent } from 'src/app/modules/request/requestdetails/requestdetails.component';
import { MyRequestsComponent } from 'src/app/modules/request/my-requests/my-requests.component';
import { NotFoundComponent } from 'src/app/components/not-found/not-found.component';
import { ROLES_CONST } from "src/app/models/Constants/ROLES_CONST";
import { MyRequestListComponent } from './my-request-list/my-request-list.component';
import { MsalGuard } from '@azure/msal-angular';
const routes: Routes = [
	// {
	// 	path: '',
	// 	children: [
	// 		{
	// 			path: 'details/:requestId/:evaluationRequestType',
	// 			component: RequestdetailsComponent
	// 		}
	// 	]
	// }
];

@NgModule({
	imports: [RouterModule.forChild(routes)],
	exports: [RouterModule]
})
export class RequestRoutingModule {}
