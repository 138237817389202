<section>
  <div class="row rowfix">
    <div class="col-md-12">
      <nav aria-label="Paginated">
        <ul class="pagination justify-content-end">
          <li class="page-item" [ngClass]='{ "disabled" : previousPage == false }'>
            <a class="page-link" (click)="goPrevPage()" tabindex="-1">Previous</a>
          </li>
          <li class="page-item" *ngFor="let i of pages" [ngClass]='{ "active" : currentPage == i }'>
            <a class="page-link" id="i" (click)="changePage(i)">{{i}}</a>
          </li>
          <li class="page-item" [ngClass]='{ "disabled" : nextPage == false }'>
            <a class="page-link" (click)="goNextPage()" tabindex="-1">Next</a>
          </li>
        </ul>
      </nav>
    </div>
  </div>
  </section>

