<mat-dialog-content class="popupDialog" style="overflow:none !important">
  <div class="closePopupDialog">
    <button mat-button mat-icon-button (click)="closePopup()">
      <mat-icon>close</mat-icon>
    </button>
  </div>
  <div class="popupHeader">Confirm submission</div>

  <div>

      <p class="modal-body">Do you want to submit the result?</p>
      <div class="Container" >

          <button mat-raised-button
          (click)="clicked('yes')">Confirm</button>

         
        <span>&nbsp;&nbsp;&nbsp;</span>
          <button mat-raised-button  
          (click)="clicked('no')">Cancel</button>
       
      
      </div>
  </div>

</mat-dialog-content>


