<section style="max-width: 500px;background-color: grey" >
  <div *ngIf="isVisibleOnError || isLearner;  else errorForm " class="mb-0">
    <div class="mt-3 changePointer background">
      Comment: <pre>{{error.comment}}</pre>
    </div>
    <div *ngIf="filePresent" class="background mt-0">
      Error File:
      <a href="{{requestDetailsModel.errorFileUrl}}">Click Here</a>
    </div>
  </div>
  <ng-template #errorForm >
    <form name="form" #form="ngForm" novalidate>
      <div class="form-group background">
        <div>
          <mat-form-field class="example-full-width">
            <textarea id="comment" required [(ngModel)]="error.comment" name="comment" #comment="ngModel" matInput placeholder="Add comment"></textarea>
          </mat-form-field>
        </div>
        <div>
          <label class="file-upload">
            <input type="file" id="chooseFile" required name="chooseFile" #chooseFile (change)="UploadedFileCheck(chooseFile.files)">
          </label>
        </div>
        <p style="font-size: 11px; color: red" *ngIf="message?.length>0"> {{message}} </p>
        <div *ngIf="fileValid" align="center">
          <button type="submit" (click)="SubmitError(chooseFile.files)" class="btn btn-primary">
            <i class="fa fa-cloud-upload"> Submit </i></button>
        </div>

      </div>
    </form>
  </ng-template>

</section>
