export class EvaluationResult {
    public evaluationRequestId: number;
    public requestId:number;
    public learnerId: string;
    public evaluationType: string;
    public result: string;
    public scorecard: string;
    public comments: string;
    public score: number;
    public errorfile:string;
    public learnername:string;
    public learneremail:string;
    public courseid:number;
    public coursename:string;
    public scorecardBlobPath:string;
    public maxScore: number;
}